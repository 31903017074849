import React, {useEffect} from 'react';
import {Button} from 'wix-ui-tpa/Button';
import {useEnvironment, useTranslation} from 'yoshi-flow-editor-runtime';
import {classes} from './PaypalButton.st.css';
import {ReactComponent as Paypal} from './Paypal.svg';
import classnames from 'classnames';
import {useControllerProps} from '../../../ControllerContext';
import {
  getCashierPaymentId,
  initCashierPayments,
  initSdk,
  startCashierFastFlowCheckout,
} from '../../../../../../domain/services/oldCashierPaymentsService';
import {useFunctionResultObservation} from '../../../../../../hooks/useFunctionResultObservation';
import {PaymentMethod} from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import {IControllerProps} from '../../../../../../types/app.types';
import {promisify} from '../../../../../../domain/lib/promisify';
import * as s from './PaypalButton.scss';

export enum PaypalButtonDataHook {
  button = 'PaypalButtonDataHook.button',
}

export function PaypalButton() {
  const {t} = useTranslation();
  const {withObservation} = useFunctionResultObservation();
  const accessibilityEnabled = useControllerProps().host.accessibilityEnabled;
  const startFastFlowCheckout = withObservation(
    useControllerProps().navigationStore as IControllerProps['navigationStore'],
    'startFastFlowCheckout'
  );
  const {openPaypalError, locale} = useControllerProps().navigationStore;
  const {isSSR} = useEnvironment();

  useEffect(() => {
    if (!isSSR) {
      initSdk();
    }
  }, [isSSR]);

  const onClick = async () => {
    await initCashierPayments(locale);
    const cashierPaymentId = await getCashierPaymentId(PaymentMethod.PayPal);
    const startFastFlowCheckoutResponse = await startFastFlowCheckout({accessibilityEnabled, cashierPaymentId});

    if (startFastFlowCheckoutResponse.wasServerResponseSuccessful) {
      await startCashierFastFlowCheckout({
        locale,
        paymentId: PaymentMethod.PayPal,
        cashierPaymentId,
        startFastFlowUrlResponse: startFastFlowCheckoutResponse.startFastFlowUrlResponse,
        openPaypalError: promisify(openPaypalError),
      });
    }
  };

  return (
    <Button
      className={classnames(s.paypalButton, classes.paypalButton)}
      data-hook={PaypalButtonDataHook.button}
      onClick={onClick}
      upgrade>
      <span className={classes.paypalButtonLabel}>{t('cart.connect_with')}</span>
      <Paypal key={''} />
    </Button>
  );
}
