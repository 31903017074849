import {APP_DEFINITION_ID} from '@wix/wixstores-client-core/dist/es/src/constants';
import CashierPayments from '@wix/cashier-payments-sdk/dist/src/sdk';

import {IContinuePaymentParams} from '@wix/cashier-payments-sdk/dist/src/cashier-sdk';
import {ServerTransactionStatus} from '@wix/cashier-common/dist/src/enums/transactions/ServerTransactionStatus';
import {StartFastFlowUrlResponse} from '@wix/wixstores-client-storefront-sdk/dist/es/src/apis/CheckoutNavigationApi/constants';
import {PaymentMethod} from '@wix/cashier-common/dist/src/enums/payments/PaymentMethod';
import {NavigationStore} from '../stores/NavigationStore';

let cashierPaymentsSdk: CashierPayments;
const getCashierPayments = async (): Promise<CashierPayments> => {
  if (!cashierPaymentsSdk) {
    cashierPaymentsSdk = await import(
      /* webpackChunkName: "cashierPayments" */ '@wix/cashier-payments-sdk/dist/src'
    ).then((cashierPayments) => cashierPayments.default);
  }
  return cashierPaymentsSdk;
};

export const initSdk = () => {
  //eslint-disable-next-line @typescript-eslint/no-floating-promises
  getCashierPayments();
};

export const initCashierPayments = async (locale: string) => {
  return (await getCashierPayments()).init({
    query: {
      appDefId: APP_DEFINITION_ID,
      locale,
    },
  });
};

export const getCashierPaymentId = async (paymentMethod: PaymentMethod): Promise<string> => {
  return (await (await getCashierPayments()).initializePayment(paymentMethod)).detailsId;
};

export const startCashierFastFlowCheckout = async (options: {
  locale: string;
  openPaypalError: NavigationStore['openPaypalError'];
  paymentId: PaymentMethod;
  cashierPaymentId: string;
  startFastFlowUrlResponse: StartFastFlowUrlResponse;
}): Promise<boolean> => {
  if (!options.startFastFlowUrlResponse.cartStatus.success) {
    return false;
  }

  if (
    [ServerTransactionStatus.Failed, ServerTransactionStatus.Declined].includes(
      options.startFastFlowUrlResponse.paymentResponse.transactionStatus
    )
  ) {
    const paypalError = await (await getCashierPayments()).localizeError(
      options.startFastFlowUrlResponse.paymentResponse.failureDetails,
      options.locale
    );
    await options.openPaypalError(paypalError);
    return false;
  }

  (await getCashierPayments()).addMethod(options.paymentId);

  const {responseToken} = options.startFastFlowUrlResponse.paymentResponse;
  const continuePaymentData: IContinuePaymentParams = {
    inNewTab: false,
    frameable: false,
    responsive: true,
    responseToken,
  };

  await (await getCashierPayments()).continuePayment(options.paymentId, continuePaymentData);

  return true;
};
